import Configuration from "../Configuration";
import UserService from "./UserService";

class BaseService {

    constructor() {
        this.config = new Configuration();
    }

    /*
     * handleResponseError
     *
     * Kümmert sich um HTTP-Fehler, sprich: der Response-Status entspricht NICHT 2xx
     */
    handleResponseError(response) {
        // Status 401, unauthorized: Der Anwender ist nicht angemeldet.

        console.log('handleResponseError, status=' + response.status + ' title: ' + response.title);
        console.log(response);
        console.log(response.text());
        switch (response.status ) {
            case 401:
                alert('401 - Unauthorized\nBitte loggen Sie sich ein.\nMöglicherweise wurden Sie wegen längerer Inaktivität automatisch abgemeldet.');
                const userService = new UserService();
                userService.logoutUser();
                window.location.reload();
                break;
            case 403:
                alert('403 - Forbidden\nSie haben keine Berechtigung, auf diese Ressource zuzugreifen.');
                break;
            case 404:
                alert('404 - Not found\nUnter dieser Adresse wurde nichts gefunden.');
                break;
            case 500:
                // alert('500 - Internal Server Error\nEs ist ein Server-interner Fehler bei der Ausführung Ihrer Anfrage aufgetreten.');
                console.log('500 - Internal Server Error\nEs ist ein Server-interner Fehler bei der Ausführung Ihrer Anfrage aufgetreten.');
                throw response;
                // break;
            default:
                alert(response.status + " - " + response.statusText + "\nEs ist ein Fehler aufgetreten.");
                console.log(response.status + " - " + response.statusText + "\nEs ist ein Fehler aufgetreten.");
                throw new Error("HTTP error, status = " + response.status);
        }

    }

}

export default BaseService;