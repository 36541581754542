
import Configuration from './../Configuration.js';


class UserService  {

    constructor() {
        this.config = new Configuration();
    }

    getCurrentUser() {
        const userString = sessionStorage.getItem('user');
        const user = JSON.parse(userString);
        return user;
    }

    saveCurrentUser(user) {
        sessionStorage.setItem('user', JSON.stringify(user));
    }

    logoutUser() {
        sessionStorage.removeItem('user');
    }

    async login(details) {
        console.log('login');
        return fetch(this.config.API_URL+'login', {
            method: 'post',
            credentials: 'include',
            headers: new Headers({'Content-Type': 'application/json'}),
            body: JSON.stringify(details, null, 4)
        })
            .then(response => {
                /*
                if(!response.ok) {
                    console.log(response.status);
                }
                 */
                return response.json();
            })
            .then(json => {
                if(json.success === true && json.user ) {
                // if(json.user) {
                    this.saveCurrentUser({email: json.user.email, firstname: json.user.firstname, lastname: json.user.lastname});
                    return json;
                }
                if(json.error) {
                    console.log(json.error);
                    return json;
                }
            })
            .catch(error => {
                console.log(error);
            });
    }


    async logout() {
        console.log('logout');
        return fetch(this.config.API_URL+'logout', {
            method: 'get',
            credentials: 'include',
            mode: "no-cors",
            headers: new Headers({'Content-Type': 'application/json'})
        })
            .then(response => {
                if(!response.ok) {
                    console.log(response.status);
                }
                this.logoutUser();
            })
            .catch(error => {
                console.log(error);
            })
    }
}

export default UserService;