import React from 'react';
import AzrService from '../shared/AzrService.js';
import AzrDetails from './AzrDetails.js';
import EditAzr from './EditAzr.js';
import NewAzr from './NewAzr.js';
import AzrPicker from './AzrPicker.js';
import spinner from '../img/icons8-spinner.gif';

class Azr extends React.Component {

    constructor(props) {
        super(props);
        this.azrService = new AzrService();
        this.state = {
            error: null,
            isLoaded: false,
            showDetails: false,
            editAzr: false,
            newAzr: null,
            loading: false,
            azrs: []
        };
        this.onSelectAzr = this.onSelectAzr.bind(this);
        this.onNewAzr = this.onNewAzr.bind(this);
        this.onCreateAzr = this.onCreateAzr.bind(this);
        this.onCalcAzr = this.onCalcAzr.bind(this);
        this.onEditAzr = this.onEditAzr.bind(this);
        this.onUpdateAzr = this.onUpdateAzr.bind(this);
        this.onDeleteAzr = this.onDeleteAzr.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onCancelEdit = this.onCancelEdit.bind(this);
    }

    componentDidMount() {
        this.getAzrs(true);
    }

    getAzrs(selectFirst = false) {
        this.azrService.retrieveItems().then(azrs => {
            this.setState({
                azrs: azrs,
                isLoaded: true
            });

            if (selectFirst && azrs.length > 0) {
                this.onSelectAzr(azrs[0].id);
            }

        });
    }


    onSelectAzr(azrId) {
        this.clearState();
        this.azrService.getAzr(azrId).then(azr => {
            this.setState({
                showDetails: true
            });
            this.props.onSelectAzr(azr);
        });

    }

    onNewAzr() {
        this.setState({
            showDetails: false,
            editAzr: false,
            newAzr: true
        });
    }

    onEditAzr() {
        this.setState({
            showDetails: true,
            editAzr: true,
            newAzr: null
        })
    }

    onDeleteAzr(id) {
        this.clearState();
        this.azrService.deleteAzr(id).then(res => {
            this.getAzrs(true);
        });
    }

    onUpdateAzr(azr) {
        this.clearState();
        const id = azr.id;
        this.azrService.updateAzr(azr)
            .then(azr => {
                this.getAzrs();
            })
            .then(azr => {
                this.onSelectAzr(id)
            });
    }

    onCreateAzr(azr) {
        this.clearState();
        this.azrService.createAzr(azr)
            .then(azr => {
                this.onSelectAzr(azr.id);
            })
            .then(azr => {
                this.getAzrs();
            });
    }

    onCancelEdit() {
        this.setState({
            showDetails: true,
            editAzr: false,
            newAzr: null
        })
    }

    onCancel() {
        this.setState({
            newAzr: false,
            showDetails: true
        })
    }

    onCalcAzr(azr) {
        this.setState( {loading : true } );
        this.azrService.calcInvoicesAndStats(azr.id)
            .then(azr => {
                this.setState( {loading : false } );
                this.onSelectAzr(azr.id);
            })
            .catch( error => {
                alert('Es gab einen Fehler bei der Erstellung der Rechnungen.');
                this.setState( {loading : false } );
                console.log('FEHLER im .catch-Block von Azr.js:onCalcAzr');
            });
    }


    clearState() {
        this.setState({
            showDetails: false,
            editAzr: false,
            newAzr: false
        });
    }


    render() {

        const showDetails = this.state.showDetails;
        const editAzr = this.state.editAzr;
        const newAzr = this.state.newAzr;
        const selectedAzr = this.props.selectedAzr;
        const selectedId = selectedAzr ? selectedAzr.id : "";

        return (
            <div className="azr-picker">

                { this.state.loading === true &&
                    <div className="loading">
                        <img className="loading-spinner" src={spinner} alt="Spinner"/>
                    </div>
                }

                <h3>Abrechnungszeiträume</h3>

                { !newAzr &&
                <AzrPicker azrs={this.state.azrs}
                           selectedAzr={selectedId}
                           onSelectAzr={this.onSelectAzr}/>
                }

                { !newAzr &&
                <button
                    type="button"
                    name="button"
                    onClick={() => this.onNewAzr()}>Neuer Azr
                </button>
                }

                { newAzr &&
                <NewAzr onSubmit={this.onCreateAzr}
                        onCancel={this.onCancel}
                    />
                }

                { showDetails && selectedAzr &&
                <AzrDetails azr={selectedAzr}
                            onEdit={this.onEditAzr}
                            onDelete={this.onDeleteAzr}
                            onCalcAzr={this.onCalcAzr}
                    />
                }

                { editAzr && selectedAzr &&
                <EditAzr azr={selectedAzr}
                         onSubmit={this.onUpdateAzr}
                         onCancel={this.onCancelEdit}
                    />
                }

            </div>
        );
    }

}

export default Azr;