import React, { useState } from 'react';

function LoginForm({Login, app_version, error}) {

    const [details, setDetails] = useState({email:"", password: ""});

    const submitHandler = e => {
        e.preventDefault();
        Login(details);
    }

    return (
        <form id="loginform" onSubmit={submitHandler}>
            <div className="form-inner">
                <h2>{process.env.REACT_APP_NAME}</h2>

                <div className="form-subtitle">
                    LOG IN
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" id="email" onChange={e => setDetails({...details, email: e.target.value})} value={details.email}/>
                </div>
                <div className="form-group">
                    <label htmlFor="password">Passwort</label>
                    <input type="password" name="password" id="password" onChange={e => setDetails({...details, password: e.target.value})} value={details.password}/>
                </div>

                { error !== "" &&
                    <div className="error login-error">{error}</div>
                }

                <input type="submit" value="Log in"/>
                <div className="form-versionnumber">Version {app_version}</div>

            </div>
        </form>
    );
}

export default LoginForm;